import { Navigator } from "../Components/Navigator/Navigator";
import { BankInfoConfirmation } from "../Components/BankInfoConfirmation/BankInfoConfirmation";
import { useParams } from "react-router-dom";
import CustomerInfoWithLogout from "../Components/Customer/Customer";
import { useAuth } from "../Context/AuthContext";
import React, { useCallback, useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import useSession from "../CustomHooks/useSession";


const apiUrl = process.env.REACT_APP_API_URL;
export const BankInfoConfirmationPage = () => {
    const [session] = useSession();
    const { id } = useParams();
    const { t } = useTranslation();
    const { setOrderData, setOrderItems, logout } = useAuth();
    const fetchOrderData = useCallback(async () => {
        try {
            const response = await fetch(`${apiUrl}/order-details`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                if (errorData.message.includes('Unauthorized') || errorData.message.includes('session expired')) {
                    toast.error(errorData.message)
                    return;
                }
            }

            const data = await response.json();
            const updatedOrderItems = data.order.OrderItems.map(item => ({
                ...item,
                refund_quantity: 0
            }));

            setOrderData(data.order);
            setOrderItems(updatedOrderItems);
        } catch ({ response }) {
            toast.error(response.data.message + "logout")
            console.error('Failed to fetch order data:', response.data.message);
        } finally {
        }
    }, [setOrderData, setOrderItems]);

    useEffect(() => {
        fetchOrderData();
    }, []);
    return (
        <>
            <ToastContainer />
            <CustomerInfoWithLogout />
            <BankInfoConfirmation id={id} />
        </>
    )
}