import React from 'react';
import { useTranslation } from 'react-i18next';

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;

  const isActive = (lang) => currentLanguage === lang;

  const buttonClass = (lang) =>
    `text-white ${isActive(lang) ? 'bg-gray-800' : 'bg-gray-600'} px-3 py-1 rounded flex items-center space-x-2`;

  return (
    <div className="flex space-x-2 z-50">
      <button
        onClick={() => i18n.changeLanguage('en')}
        className={buttonClass('en')}
      >
        <span role="img" aria-label="English" className="text-lg">🇺🇸</span>
        <span>EN</span>
      </button>
      <button
        onClick={() => i18n.changeLanguage('ar')}
        className={buttonClass('ar')}
      >
        <span role="img" aria-label="Arabic" className="text-lg">🇸🇦</span>
        <span>AR</span>
      </button>
    </div>
  );
};
